import React from 'react';
import Slider from "react-slick";
import styles from '../modules/sliderGrimoire.module.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function SliderGrimoire() {

  const settings = {
    centerMode: true,
    slidesToShow: 2,
    speed: 500,
    dots: true,
    variableWidth: true
  };

  const items = [
    {name: 'Profile Name 1', heroImg: 'LD', text1: 'Tincidunt adivquet nulla sed lorem at habitant nibh viverra in. Pulvinar lacus sagittis donec consectetur nisl.', text2: 'Lorem ipsum dolor sit amet consectetur. Sed egestas enim soldivcitudin amet ut et ultrices. Vulputate nunc sed bibendum turpis enim. Nulla id quam scelerisque moldivs neque.'},
    {name: 'Profile Name 2', heroImg: 'SR', text1: 'Tincidunt adivquet nulla sed lorem at habitant nibh viverra in. Pulvinar lacus sagittis donec consectetur nisl.', text2: 'Lorem ipsum dolor sit amet consectetur. Sed egestas enim soldivcitudin amet ut et ultrices. Vulputate nunc sed bibendum turpis enim. Nulla id quam scelerisque moldivs neque.'},
    {name: 'Profile Name 3', heroImg: 'SSR', text1: 'Tincidunt adivquet nulla sed lorem at habitant nibh viverra in. Pulvinar lacus sagittis donec consectetur nisl.', text2: 'Lorem ipsum dolor sit amet consectetur. Sed egestas enim soldivcitudin amet ut et ultrices. Vulputate nunc sed bibendum turpis enim. Nulla id quam scelerisque moldivs neque.'},
  ]

  return (
    <div className="slider-container" style={{marginTop: '80px'}}>
      <Slider {...settings}>
        {items.map((item) => {
          const {name, heroImg, text1, text2} = item;
          return (
            <div style={{ width: 600 }}>
              <div className={styles.card}>
                <img className={styles.left} alt='Ethersoul' src={`/img/${heroImg}.png`}/>
                <div className={styles.right}>
                  <div className={styles.name}>{name}</div>
                  <div className={styles.text}>
                    {text1}
                    {text2}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  );
}

export default SliderGrimoire;
