import styles from '../modules/section3.module.css';
import SliderHero from '../components/sliderHero';
function Section3() {
  return (
    <div className={styles.section}>
      <div className={styles.content} id="Featured">
        <div className={styles.top}>
          <div className={styles.heading}>Featured Heroes and Monsters</div>
          <img className={styles.devider} alt='Ethersoul' src="/img/bottom divider gold center.png"/>
          <div className={styles.text}>
            Discover the heroes and monsters that make Ethersoul: Showdown extraordinary. Our game features a diverse array of characters, from Mages and Elves to Humans and Cyborgs. Each hero comes with a detailed backstory and unique abilities, while our monsters provide valuable boosts to your rewards. Explore our gallery to meet these champions and creatures, and learn how they can help you dominate the Ethersoul Arena.
          </div>
        </div>
        <div className={styles.bottom}>
          <SliderHero/>
        </div>
      </div>
    </div>
  );
}

export default Section3;
