import styles from './modules/main.module.css';

import {
  Header,
  Footer,
  Section1,
  Section2,
  Section3,
  Section4,
  Section5,
  Section6,
  Section7,
  Section8,
  Section9,
  Section10,
  Section11,
} from './sections/index';

function App() {
  return (
    <div className={styles.backgound}>
      <Header/>
      <Section1/>
      <Section2/>
      <Section3/>
      <Section4/>
      <Section5/>
      <Section6/>
      <Section7/>
      <Section8/>
      <Section9/>
      <Section10/>
      <Section11/>
      <Footer/>
    </div>
  );
}

export default App;
