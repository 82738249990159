import styles from '../modules/section11.module.css';

function Section11() {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.title}>Rarity Benefits</div>
          <img className={styles.devider} alt='Ethersoul' src="/img/bottom divider gold center.png"/>
          <div className={styles.text}>
            The rarity of your NFTs significantly impacts your gameplay and rewards:
          </div>
          <div className={styles.rarity}>
            <div className={styles.leftText}>Hero NFT Rarities</div>
            <div className={styles.rightText}>Monster NFT Rarities</div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.item}>
            <img className={styles.stone} alt='Ethersoul' src="/img/xR.png"/>
            <div className={styles.textStone}>
              R: 3 battles/day, <br/>
              2-4% reward boost
            </div>
          </div>
          <div className={styles.item}>
            <img className={styles.stone} alt='Ethersoul' src="/img/xSR.png"/>
            <div className={styles.textStone}>
              SR: 5 battles/day, <br/>
              4.1-6% reward boost 
            </div>
          </div>
          <div className={styles.item}>
            <img className={styles.stone} alt='Ethersoul' src="/img/xSSR.png"/>
            <div className={styles.textStone}>
              SSR: 7 battles/day, <br/>
              6.1-8% reward boost 
            </div>
          </div>
          <div className={styles.item}>
            <img className={styles.stone} alt='Ethersoul' src="/img/xUR.png"/>
            <div className={styles.textStone}>
              UR: 10 battles/day, <br/>
              9.1-10% reward boost 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section11;
