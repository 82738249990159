import styles from '../modules/header.module.css';
import { isMobile } from 'react-device-detect';
import React, { useState, useEffect } from 'react';

function Header() {

  const [menuOpen, setMenu] = useState(false);

  useEffect(() => {
    console.log({menuOpen})
  }, [menuOpen])

  const goTo = (e, d) => {
    setMenu(false);
    e.preventDefault();
    const section = document.querySelector(d);
    var headerOffset = 100;
    var elementPosition = section.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.scrollY - headerOffset;
    section.scrollIntoView( { top: offsetPosition, behavior: 'smooth', block: 'start', inline: 'nearest' } );
  }

  const toTop = (e) => {
    setMenu(false);
    e.preventDefault();
    const section = document.querySelector('#Home');
    section.scrollIntoView( { behavior: 'smooth', block: 'end' } );
  }

  return (
  <>
    {isMobile ? 
      <>
        <div className={`${styles.dropDown} ${menuOpen ? styles.open : styles.close}`}>
          <img onClick={(e) => toTop(e)} className={styles.logoMobile} alt='Ethersoul' src="/img/logo-text-white.png"/>
          <div onClick={(e) => goTo(e, '#About')} className={styles.menuItem}>About Game</div>
          <div onClick={(e) => goTo(e, '#Featured')} className={styles.menuItem}>Heroes & Monsters</div>
          <div onClick={(e) => goTo(e, '#Gameplay')} className={styles.menuItem}>Gameplay</div>
          <div onClick={(e) => goTo(e, '#Howto')} className={styles.menuItem}>How to Play</div>
          <div onClick={(e) => goTo(e, '#Grimoire')} className={styles.menuItem}>Ethersoul Grimoire</div>
          <div onClick={(e) => goTo(e, '#Ethersoul')} className={styles.menuItem}>Ethersoul S Token</div>
        </div>
        <div className={`${styles.menuMobile} ${menuOpen ? styles.round : ''}`}>
          <div onClick={() => setMenu(!menuOpen)} className={styles.close}>
            {  menuOpen ? 
              <svg className={styles.icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                <path d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"/>
              </svg>
            : 
              <svg className={styles.icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" d="M19 4a1 1 0 01-1 1H2a1 1 0 010-2h16a1 1 0 011 1zm0 6a1 1 0 01-1 1H2a1 1 0 110-2h16a1 1 0 011 1zm-1 7a1 1 0 100-2H2a1 1 0 100 2h16z"/>
              </svg>
            }
          </div>
        </div>
      </>
    :
      <div className={styles.header} id="Header">
        <div className={styles.content}>
          <img onClick={(e) => toTop(e)} className={styles.logo} alt='Ethersoul' src="/img/logo-text-white.png"/>
          <div className={styles.menu}>
            <li onClick={(e) => goTo(e, '#About')} className={styles.menuItem}>About Game</li>
            <li onClick={(e) => goTo(e, '#Featured')} className={styles.menuItem}>Heroes & Monsters</li>
            <li onClick={(e) => goTo(e, '#Gameplay')} className={styles.menuItem}>Gameplay</li>
            <li onClick={(e) => goTo(e, '#Howto')} className={styles.menuItem}>How to Play</li>
            <li onClick={(e) => goTo(e, '#Grimoire')} className={styles.menuItem}>Ethersoul Grimoire</li>
            <li onClick={(e) => goTo(e, '#Ethersoul')} className={styles.menuItem}>Ethersoul S Token</li>
          </div>
          <img className={styles.download} alt='Ethersoul' src="/img/button download.png"/>
        </div>
          <img className={styles.devider} alt='Ethersoul' src="/img/devider black center.png"/>
      </div>
    }
  </>
  );
}

export default Header;
