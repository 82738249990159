import styles from '../modules/section8.module.css';

const items = [
  {name: 'Presale:', percentage: '30%', info: '30% (3,000,000,000 ESC)'},
  {name: 'Game Rewards:', percentage: '35%', info: '35% (3,500,000,000 ESC)'},
  {name: 'Development Fund: ', percentage: '15%', info: '15% (1,500,000,000 ESC)'},
  {name: 'Marketing Fund:', percentage: '10%', info: '10% (1,000,000,000 ESC) '},
  {name: 'Team and Advisors: ', percentage: '7%', info: '7% (700,000,000 ESC) '},
  {name: 'Reserve', percentage: '3%', info: '3% (300,000,000 ESC) '},
];

function Section8() {
  return (
    <div className={styles.section}>
      <div className={styles.content} id="Ethersoul">
        <div className={styles.top}>
          <div className={styles.title}>Ethersoul S Token</div>
          <img className={styles.devider} alt='Ethersoul' src="/img/bottom divider gold center.png"/>
          <div className={styles.text}>
            Ethersoul S (ESC) is the primary cryptocurrency for the Ethersoul: Showdown ecosystem. As a deflationary token, ESC 
            is designed to increase in value over time while providing utility within the game. With a total supply of 
            10,000,000,000 tokens, ESC facilitates in-game transactions, rewards, and participation in various game modes and events. 
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.left}>
            <div className={styles.infoTitle}>Token Information</div>

            <div className={styles.deviderBar}></div>

            <div className={styles.item}>
              <div className={styles.topText}>
                <div className={styles.progressName}>Total Supply</div>
                <div className={styles.progressInfo}>
                  10,000,000,000 ESC
                </div>
              </div>
            </div>

            <div className={styles.deviderBar}></div>

            <div className={styles.item}>
              <div className={styles.progressName}>Presale Phases</div>
              <div className={styles.paragraph}>
                There will be three presale phases, each offering 10% of the total token supply. These presales provide early investors the opportunity to purchase ESC at progressively higher prices: 
              </div>
            </div>

            <div className={styles.deviderBar}></div>

            <div className={styles.item}>
              <div className={styles.topText}>
                <div className={styles.progressName}>First Presale</div>
                <div className={styles.progressInfo}>
                  1,000,000,000 ESC at $0.0005 per token 
                </div>
              </div>
            </div>

            <div className={styles.deviderBar}></div>

            <div className={styles.item}>
              <div className={styles.topText}>
                <div className={styles.progressName}>Second Presale</div>
                <div className={styles.progressInfo}>
                 1,000,000,000 ESC at $0.001 per token
                </div>
              </div>
            </div>

            <div className={styles.deviderBar}></div>

            <div className={styles.item}>
              <div className={styles.topText}>
                <div className={styles.progressName}>Third Presale</div>
                <div className={styles.progressInfo}>
                 1,000,000,000 ESC at $0.002 per token
                </div>
              </div>
            </div>

           
          </div>

          <div className={styles.right}>
            <div className={styles.infoTitle}>Token Distribution</div>
            <div className={styles.deviderBar}></div>
            {items.map((item) => {
              const {name, percentage, info} = item;
              return (
                <div className={styles.progressItem}>
                  <div className={styles.topText}>
                    <div className={styles.progressName}>
                      {name}
                    </div>
                    <div className={styles.progressInfo}>
                      {info}
                    </div>
                  </div>
                  <div className={styles.progress}>
                    <div className={styles.progressBar}>
                      <div className={styles.bar} style={{'--width': percentage}}></div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <img className={styles.download} alt='Ethersoul' src="/img/buy.png"/>
      </div>
    </div>
  );
}

export default Section8;
