// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/font/Bildad.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/font/PTSerif-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../public/font/Poppins-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../public/font/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@font-face {
  font-family: 'Bildad';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
  font-family: 'PT Serif';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
  font-family: 'Poppins light';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
  font-family: 'Poppins regular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

:root {
  --blue: #2B4E9F;
  --dark-blue: #0A1123;
  --light-blue: #B1E6FF;
  --black: #000000;
  --white: #ffffff;
  --accent: #F5F5F5;
  --gold: #CBAC76;
}

.main_backgound__roXdG {
  background: var(--dark-blue);
}

body {
  background: var(--dark-blue);
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  /* border: 1px dotted #ff00f3; */
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #474747;
}

::-webkit-scrollbar-thumb {
  background: #2a2a2a;
}

::-webkit-scrollbar-thumb:hover {
  background: #c712fc;
}`, "",{"version":3,"sources":["webpack://./src/modules/main.module.css"],"names":[],"mappings":";AACA;EACE,qBAAqB;EACrB,4CAAwC;AAC1C;;AAEA;EACE,uBAAuB;EACvB,4CAAiD;AACnD;;AAEA;EACE,4BAA4B;EAC5B,4CAA+C;AACjD;;AAEA;EACE,8BAA8B;EAC9B,4CAAiD;AACnD;;AAEA;EACE,eAAe;EACf,oBAAoB;EACpB,qBAAqB;EACrB,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;EAC5B,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,gCAAgC;AAClC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["\r\n@font-face {\r\n  font-family: 'Bildad';\r\n  src: url('../../public/font/Bildad.ttf');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'PT Serif';\r\n  src: url('../../public/font/PTSerif-Regular.ttf');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Poppins light';\r\n  src: url('../../public/font/Poppins-Light.ttf');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Poppins regular';\r\n  src: url('../../public/font/Poppins-Regular.ttf');\r\n}\r\n\r\n:root {\r\n  --blue: #2B4E9F;\r\n  --dark-blue: #0A1123;\r\n  --light-blue: #B1E6FF;\r\n  --black: #000000;\r\n  --white: #ffffff;\r\n  --accent: #F5F5F5;\r\n  --gold: #CBAC76;\r\n}\r\n\r\n.backgound {\r\n  background: var(--dark-blue);\r\n}\r\n\r\nbody {\r\n  background: var(--dark-blue);\r\n  overflow-x: hidden;\r\n}\r\n\r\n* {\r\n  margin: 0;\r\n  padding: 0;\r\n  /* border: 1px dotted #ff00f3; */\r\n}\r\n\r\n::-webkit-scrollbar {\r\n  width: 5px;\r\n}\r\n\r\n::-webkit-scrollbar-track {\r\n  background: #474747;\r\n}\r\n\r\n::-webkit-scrollbar-thumb {\r\n  background: #2a2a2a;\r\n}\r\n\r\n::-webkit-scrollbar-thumb:hover {\r\n  background: #c712fc;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backgound": `main_backgound__roXdG`
};
export default ___CSS_LOADER_EXPORT___;
