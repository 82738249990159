import styles from '../modules/section4.module.css';

function Section4() {
  return (
    <div className={styles.section}>
      <div className={styles.content} id="Gameplay">
        <div className={styles.top}>
          <div className={styles.heading}>Overview of Gameplay Mechanics</div>
          <img className={styles.devider} alt='Ethersoul' src="/img/bottom divider gold center.png"/>
          <div className={styles.text}>
            Ethersoul: Showdown is a strategic card game where players draft heroes, summon monsters, and cast spells to 
            outmaneuver their opponents. The goal is to gather 16 souls or deplete your opponent's draw pile. With shifting 
            battlegrounds and intense gameplay, every match tests your strategy and skill.
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.card}>
            <img className={styles.img} alt='Ethersoul' src="/img/newBos1.png"/>
            <div className={styles.title}>Showdown</div>
            <div className={styles.cardText}>
              Draft your heroes and engage in duels 
              to earn Ethersoul S coins. Each hero 
              has a stamina limit, allowing a certain 
              number of battles per day based on 
              their rarity. 
            </div>
          </div>
          <div className={styles.card}>
            <img className={styles.img} alt='Ethersoul' src="/img/newBos2.png"/>
            <div className={styles.title}>Wild hunt</div>
            <div className={styles.cardText}>
              Send your monsters on a wildhunt to 
              bring back valuable rewards. The 
              higher the monster’s rarity, the greater 
              the rewards they retrieve. 
            </div>
          </div>
          <div className={styles.card}>
            <img className={styles.img} alt='Ethersoul' src="/img/newBos3.png"/>
            <div className={styles.title}>Showdown of greed</div>
            <div className={styles.cardText}>
              Stake Ethersoul S coins in high-stakes 
              battles for grand rewards. Compete 
              for the ultimate prize. 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section4;
