import styles from '../modules/section1.module.css';

function Section1() {
  return (
    <div className={styles.section}>
      <div className={styles.content} id="Home">
        <div className={styles.innerLeft}>
          <div className={styles.top}>Unleash Your Power in</div>
          <div className={styles.mid}>
            ETHERSOUL
            SHOWDOWN
          </div>
          <div className={styles.bottom}>
            Experience the thrill of epic battles and unique <br/> gameplay mechanics in Ethersoul Showdown.
          </div>
          <div className={styles.bottomPart}>
            <img className={styles.download} alt='Ethersoul' src="/img/download game.png"/>
            <div className={styles.learnMore}>Learn more</div>
          </div>
        </div>
        <div className={styles.innerRight}>
          <img className={styles.logo} alt='Ethersoul' src="/img/Logo-full.png"/>
        </div>
      </div>
    </div>
  );
}

export default Section1;
