import styles from '../modules/section10.module.css';

function Section10() {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.title}>How to Use NFT</div>
          <img className={styles.devider} alt='Ethersoul' src="/img/bottom divider gold left.png"/>
          <div className={styles.text}>
            Utilizing NFTs in Ethersoul: Showdown is easy. 
            Hero NFTs are necessary for participating in 
            Showdownmode, where you command your heroes 
            in duels to earn ESC. Monster NFTs boost your 
            rewards basedon their rarity and can also be 
            used in Wildhunt mode for additional rewards
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section10;
