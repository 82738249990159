import styles from '../modules/section7.module.css';
import SliderGrimoire from '../components/sliderGrimoire';

function Section7() {
  return (
    <div className={styles.section}>
      <div className={styles.content} id="Grimoire">
        <div className={styles.top}>
          <div className={styles.topLeft}>
            <div className={styles.title}>Ethersoul Grimoire</div>
            <img className={styles.devider} alt='Ethersoul' src="/img/bottom divider black left.png"/>
            <div className={styles.text}>
              <p className={styles.par}>
                Lorem ipsum dolor sit amet consectetur. Congue facilisi auctor in 
                laoreet egestas nunc. Lectus nibh in arcu mattis turpis lacus. Eu 
                arcu mattis purus non lectus nibh eget. Lorem nunc interdum 
                pulvinar gravida ultrices et. Elementum nulla commodo montes tortor. 
              </p>
              <p className={styles.par}>
                Lorem ipsum dolor sit amet consectetur. Aliquet sed tristique 
                convallis penatibus. Vulputate semper lacus tellus commodo nibh 
                enim id et nunc. Arcu neque parturient facilisis eget. Dictum vel leo 
                eros venenatis fusce.
              </p>
              <p className={styles.par}>
                Vel lectus adipiscing ultrices egestas adipiscing. Convallis gravida 
                pharetra morbi velit diam ut viverra nam nunc. Non quisque lectus 
                mi lorem at hac sed.
              </p>
            </div>
          </div>
          <img className={styles.img} alt='Ethersoul' src="/img/dragon.png"/>
        </div>
        {/* <div className={styles.bottom}>
          <SliderGrimoire/>
        </div> */}
      </div>
    </div>
  );
}

export default Section7;
