import styles from '../modules/section6.module.css';

function Section6() {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.cta}>
          <div className={styles.title}>Join the adventure today!</div>
          <img className={styles.devider} alt='Ethersoul' src="/img/bottom divider gold center.png"/>
          <div className={styles.text}>
            Purchase Hero and Monster NFTs, collect Ethersoul S coins, 
            and become a champion in the Ethersoul Arena.
          </div>
          <img className={styles.button} alt='Ethersoul' src="/img/earning.png"/>
        </div>
      </div>
    </div>
  );
}

export default Section6;
