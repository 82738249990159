import React from 'react';
import Slider from "react-slick";
import styles from '../modules/sliderHero.module.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { isMobile } from 'react-device-detect';
import useWindowDimensions from './useWindowDimensions';

function SliderHero() {

  const { height, width } = useWindowDimensions();

  console.log({width, height})

  const sliderWidth = width <= 880 ? width - 60 : width - 880;

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 1,
    speed: 500,
    dots: true,
    variableWidth: true,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          
        }
      },
      {
        breakpoint: 480,
        settings: {
          
        }
      }
    ]
  };

  const items = [
    {name: 'Hero Name 1', heroImg: 'hero 1', monsterImg: 'card bottom', text: 'Lorem ipsum dolor sit amet consectetur. Sed egestas enim soldivcitudin amet ut et ultrices. Vulputate nunc sed bibendum turpis enim. Nulla id quam scelerisque moldivs neque. Tincidunt adivquet nulla sed lorem at habitant nibh viverra in. Pulvinar lacus sagittis donec consectetur nisl.'},
    {name: 'Hero Name 2', heroImg: 'hero 1', monsterImg: 'card bottom', text: 'Lorem ipsum dolor sit amet consectetur. Sed egestas enim soldivcitudin amet ut et ultrices. Vulputate nunc sed bibendum turpis enim. Nulla id quam scelerisque moldivs neque. Tincidunt adivquet nulla sed lorem at habitant nibh viverra in. Pulvinar lacus sagittis donec consectetur nisl.'},
    {name: 'Hero Name 3', heroImg: 'hero 1', monsterImg: 'card bottom', text: 'Lorem ipsum dolor sit amet consectetur. Sed egestas enim soldivcitudin amet ut et ultrices. Vulputate nunc sed bibendum turpis enim. Nulla id quam scelerisque moldivs neque. Tincidunt adivquet nulla sed lorem at habitant nibh viverra in. Pulvinar lacus sagittis donec consectetur nisl.'},
  ];

  return (
    <div style={{marginTop: isMobile ? '20px' : '80px'}}>
      <Slider {...settings}>
        {items.map((item) => {
          const {name, heroImg, monsterImg, text} = item;
          return (
            <div style={{ width: sliderWidth }}>
              <div className={styles.card}>
                <div className={styles.left}>
                  <div className={styles.name}>{name}</div>
                  <img className={styles.devider} alt='Ethersoul' src="/img/devider purple left.png"/>
                  <div className={styles.text}>
                    {text}
                  </div>
                  <img className={styles.cardBottom} alt='Ethersoul' src={`/img/${monsterImg}.png`}/>
                </div>
                <div className={styles.right}>
                  <img className={styles.img} src={`/img/${heroImg}.png`} alt="img" draggable="false"/>
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  );
}

export default SliderHero;
