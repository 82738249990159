import styles from '../modules/section2.module.css';

function Section2() {
  return (
    <div className={styles.section}>
      <div className={styles.content} id="About">
        <div className={styles.innerLeft}>
          <div>
            <div className={styles.welcome}>Welcome To</div>
            <div className={styles.heading}>Ethersoul: Showdown</div>
            <img className={styles.devider} alt='Ethersoul' src="/img/bottom divider gold left.png"/>
            <div className={styles.text}>
              An innovative hybrid fantasy card game that combines <br/> free-to-play and play-to-earn mechanics
            </div>
          </div>
          <img className={styles.planet} alt='Ethersoul' src="/img/planet.png"/>
        </div>
        <div className={styles.innerRight}>
          <div className={styles.box}>
            <img className={styles.icon} alt='Ethersoul' src="/img/1.png"/>
            <div className={styles.cardInfo}>
              <div className={styles.h1}>Built on the Ethereum blockchain</div>
              <div className={styles.textSmall}>Ethersoul: Showdown allows you tocommand <br/> legendary heroes and powerful monsters in epic duels</div>
            </div>
          </div>
          <div className={styles.box}>
            <img className={styles.icon} alt='Ethersoul' src="/img/2.png"/>
            <div className={styles.cardInfo}>
              <div className={styles.h1}>Engage in strategic battle</div>
              <div className={styles.textSmall}>Earn Ethersoul S coins, and enhance your gameplay <br/> with unique NFT</div>
            </div>
           </div>
          <div className={styles.box}>
            <img className={styles.icon} alt='Ethersoul' src="/img/3.png"/>
            <div className={styles.cardInfo}>
              <div className={styles.h1}>Realm Where Every Move Matters</div>
              <div className={styles.textSmall}>Immerse yourself in the mystical realm of Ilirium and <br/> experience a game where every move counts</div>
            </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
