import styles from '../modules/section9.module.css';

const packs = [
  {name: 'Hero Pack', price: '99', img: 'nft1', text: 'Hero Pack: Contains hero NFTs essential for Showdown mode. Each pack includes common-grade monsters to complement your heroes.'},
  {name: 'Monster Pack', price: '99', img: 'nft2', text: 'Features monsters of varying rarities, each offering different reward boosts. The higher the rarity, the greater the boost. '},
  {name: 'Selection Pack', price: '99', img: 'nft3', text: 'A mix of heroes and monsters, giving you the flexibility to enhance your collection strategically. '},
]

function Section9() {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.top}>
        <div className={styles.title}>Overview of NFT Packs</div>
          <img className={styles.devider} alt='Ethersoul' src="/img/bottom divider gold center.png"/>
          <div className={styles.text}>
            Explore our exclusive NFT packs designed to enhance your gameplay. Choose from Hero Packs, Monster 
            Packs, and Selection Packs, each containing unique NFTs that provide various benefits and boosts.
          </div>
        </div>
        <div className={styles.bottom}>
          {packs.map((pack) => {
            const {name, price, img, text} = pack;
            return (
              <div className={styles.card}>
                <div className={styles.cardTop}>
                  <div className={styles.price}>
                    ${price}
                  </div>
                  <img className={styles.pack} alt='Ethersoul' src={`/img/${img}.png`}/>
                </div>
                <img className={styles.deviderMiddle} alt='Ethersoul' src="/img/bottom divider gold center.png"/>
                <div className={styles.cardBottom}>
                  <div className={styles.packTitle}>{name}</div>
                  <div className={styles.packText}>
                    {text}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <img className={styles.devider} alt='Ethersoul' src="/img/devider gold center wide.png"/>
      </div>
    </div>
  );
}

export default Section9;
