import styles from '../modules/section5.module.css';

function Section5() {
  return (
    <div className={styles.section} id="Howto">
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.title}>
            How to Play<br/>How to Earn
          </div>
          <img className={styles.devider} alt='Ethersoul' src="/img/bottom divider gold left.png"/>
          <div className={styles.stepsObject}>
            <div className={styles.stepsLeft}>
              <div className={styles.steps}>
                <div className={styles.step}>1</div>
                <div className={styles.line}></div>
                <div className={styles.step}>2</div>
                <div className={styles.line}></div>
                <div className={styles.step}>3</div>
              </div>
            </div>
            <div className={styles.stepsRight}>
              <div className={styles.stepItem}>
                <div className={styles.stepName}>Step 1</div>
                <div className={styles.stepText}>
                  Start by collecting Hero NFTs to participate in 
                  Showdown duels and earn Ethersoul S coins
                </div>
              </div>
              <div className={styles.stepItem}>
                <div className={styles.stepName}>Step 2</div>
                <div className={styles.stepText}>
                  Enhance your rewards with Monster NFTs, which 
                  provide percentage boosts based on their rarity.
                </div>
              </div>
              <div className={styles.stepItem}>
                <div className={styles.stepName}>Step 3</div>
                <div className={styles.stepText}>
                  Engage in battles, stake in wildhunts, or compete 
                  in tournaments to maximize your earnings and 
                  climb the leaderboards.
                </div>
              </div>
            </div>
          </div>
          <img className={styles.download} alt='Ethersoul' src="/img/download game.png"/>
        </div>
        <img className={styles.img} alt='Ethersoul' src="/img/elf.png"/>
      </div>
    </div>
  );
}

export default Section5;
