// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_header__VIvX1 {
  width: 100%;
  height: 100px;
  background: radial-gradient(at 50% 200%, rgba(0, 212, 255, 1) -50%, rgba(2, 0, 36, 0) 70%);
  align-content: center;
  margin-top: -100px;
}`, "",{"version":3,"sources":["webpack://./src/modules/footer.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,0FAA0F;EAC1F,qBAAqB;EACrB,kBAAkB;AACpB","sourcesContent":[".header {\r\n  width: 100%;\r\n  height: 100px;\r\n  background: radial-gradient(at 50% 200%, rgba(0, 212, 255, 1) -50%, rgba(2, 0, 36, 0) 70%);\r\n  align-content: center;\r\n  margin-top: -100px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `footer_header__VIvX1`
};
export default ___CSS_LOADER_EXPORT___;
